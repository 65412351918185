<template>
    <div>
        <common-header></common-header>

        <div class="subtop">
            <div class="subtopin">
                <img :src="require('@/assets/img/sub/sub_top_img.png')"/>
                <div class="subtit">SUPPORT</div>
            </div>
        </div>

        <div class="sbreadcrumb">
            <div class="bdinner">
                <span><img :src="require('@/assets/img/sub/home_icon.svg')"></span>
                <span><img :src="require('@/assets/img/sub/next_arrow.svg')"></span>
                <span class="locatit">Support</span>
            </div>
        </div>

        <div class="scontainer">
            <div class="section">
                <div class="sectionin">
                    <div class="spdown">
                        <a href=""><img :src="require('@/assets/img/sub/download_icon.svg')">
                            TNBOX_MPS_메뉴얼.pdf</a>
                    </div>
                    <div class="manual_title_wrap">
                        <div class="stit">사용자 메뉴얼</div>
                        <p class="stxt">
                            어떤 도움이 필요하신가요?
                        </p>
                    </div>

                    <div class="m_spmenu">

                        <div class="sdropdown">
                            <select class="dropbtn">
                                <option>MPS 시작하기</option>
                                <option>거래처 등록</option>
                                <option>MPS 설치</option>
                                <option>모니터링 A/S</option>
                                <option>모니터링 매출</option>
                                <option>모바일 기능</option>
                                <option>부가기능</option>
                            </select>
                            <!-- <button >MPS 시작하기</button>
                             <div class="sdropdown-content">
                                 <a href="#">MPS 시작하기</a>
                                 <a href="#">거래처 등록</a>
                                 <a href="#">MPS 설치</a>
                                 <a href="#">모니터링 A/S</a>
                                 <a href="#">모니터링 매출</a>
                                 <a href="#">모바일 기능</a>
                                 <a href="#">부가기능</a>
                             </div>-->
                        </div>

                    </div>


                    <div class="row">
                        <div class="smenu col-lg-2 col-md-2 m_smenu">
                            <div class="menutit menuon">MPS 시작하기</div>
                            <div class="menutit">거래처 등록</div>
                            <div class="menutit">MPS 설치</div>
                            <div class="menutit">모니터링 A/S</div>
                            <div class="menutit">모니터링 매출</div>
                            <div class="menutit">모바일 기능</div>
                            <div class="menutit">부가기능</div>


                        </div>


                        <div class="spcont col-lg-10 col-md-10">

                            <div class="rightc">
                                <h3>MPS 시작하기</h3>

                                <div class="supcont">
                                    <div>
                                        <p class="font-weight-bold">1.메인페이지 최상단 우측에 회원가입과 로그인이 배치되어 있습니다.</p>
                                        <img :src="require('@/assets/img/sub/supportimg.png')">
                                    </div>
                                    <div>
                                        <p class="font-weight-bold">2.회원가입 화면으로 임대 업체 회원가입을 선택 후 약관 동의와 필수 회사정보를 입력 하면
                                            가입 절차는 완료 됩니다.</p>
                                        <img :src="require('@/assets/img/sub/menual02.svg')">
                                        <span class="font_size">2-1. 임대업체 회원가입</span>
                                        <p>임대업체 회원가입을 선택해야 프린터 렌탈
                                            서비스를 사용할 수 있습니다. 일반회원 가입의
                                            경우 프린터 렌탈 서비스를 제외한 업계 동향,
                                            제품 정보를 확인할 수 있습니다.</p>
                                        <span class="font_size">2-2. 필수 회사정보</span>
                                        <p>빨간 별이 표시된 항목은 꼭 입력해야 다음
                                            단계로 넘어 갑니다.</p>
                                        <span class="font_size">2-3. 회원가입 완료</span>
                                        <p>회원가입 절차 완료 후 관리자 승인이 필요
                                            합니다. 관리자 승인은 1~2일 정도 소요됩니다.</p>
                                    </div>
                                    <div>
                                        <p class="font-weight-bold">3.임대업체 코드 관리 화면으로 프린터 렌탈 서비스에서 사용 될 프린터 기종, A/S 증상,
                                            A/S 결과, 품목 등 기준 정보를
                                            제공합니다.
                                            기준 정보를 제외한 다른 코드를 사용할 시에는 등록해서 사용합니다. </p>
                                        <img :src="require('@/assets/img/sub/menual03.svg')">
                                        <span class="font_size">3-1. 코드 등록</span>
                                        <p>프린터 모델, 품목, A /S 관련 코드는
                                            기본적으로 제공되고 있으며, 없을 경우
                                            추가해서 사용합니다.</p>
                                    </div>
                                    <div>
                                        <p class="font-weight-bold">4.임대업체 직원 관리 화면으로 직원 정보를 등록합니다.</p>
                                        <img :src="require('@/assets/img/sub/menual04.svg')">
                                        <span class="font_size">4-1. 직원 등록</span>
                                        <p>기존 직원분들의 정보를 등록해 주세요.</p>
                                        <span class="font_size">4-2. 권한 그룹</span>
                                        <p>직원분들에게 권한을 부여하여 관리 할 수 있 습니다.</p>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>


                </div>
            </div>

        </div>
        <section>
            <common-site-map></common-site-map>
        </section>
            <common-footer></common-footer>
    </div>
</template>

<style scoped>
    /*_-------------------------------------------------------------------서브페이지 공통*/

    #header {
        width: 100%;
        margin: 0 auto;
        height: 100px;

    }

    .m_header {
        display: none;
    }

    .header_container {
        position: absolute;
        height: 100px;
        width: 100%;
        left: 0;
        z-index: 1000;
    }

    .open {
        display: block;
        /*background: #f9f9f9;*/
    }

    /*메뉴 마우스 호버시 나오는 영역입니다._______________________________ start*/
    .header_container {
        position: relative;
        /*background: #f9f9f9;*/
        height: 300px;
    }

    /*메뉴 마우스 호버시 나오는 영역입니다._______________________________ end*/
    .header_container::before {
        content: "";
        position: relative;
        top: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 100px;
    }

    .header_container.open::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 100px;
        background-color: #fff;
    }


    .header_container .inner {
        position: relative;
        width: 100%;
        max-width: 1200px;
        height: 100px;
        margin: 0 auto;
    }


    .header_container .logo {
        position: absolute;
        top: 28px;
        z-index: 111;
    }


    .header_menu_wrap {
        position: absolute;
        height: 100px;
        z-index: 30;
        width: 100%;
        left: 0px;
        top: 37px;
    }

    .header_menu_wrap > ul {
        display: flex;
        justify-content: space-between;
        list-style: none;
        width: 1200px;
        margin: 0 auto;
        position: relative;
        padding-left: 281px;
    }

    .header_menu_wrap > ul > li {
        width: 170px;
        display: inline-block;
        text-align: right;
        position: relative;
    }

    .header_menu_wrap > ul > li > a:hover {
        color: #0f70b7;
    }

    .sub_menu > ul > li:hover {
        text-decoration: underline;
        cursor: pointer;
    }


    .header_menu_wrap > ul > li > a {

        font-size: 1.8rem;
        letter-spacing: -0.54px;
        line-height: 27px;
        font-weight: 700;
        color: #000;
        font-family: 'Noto Sans KR', sans-serif;
    }

    .header_menu_wrap > ul > li > a:hover {
        text-decoration: none;
    }


    .sub_menu {
        position: absolute;
        top: 63px;
        left: 50px;
        z-index: 30;
        padding-bottom: 36px;
        display: none;
    }

    .menu01 {
        left: 109px;
    }

    .menu02 {
        left: 71px;
    }

    .menu03 {
        left: 75px;
    }

    .menu04 {
        left: 86px;
    }

    .menu05 {
        left: 68px;
    }


    .sub_menu > ul {
        /* list-style: none;
         padding: 0;
         max-width: 1200px;
         margin: 0 auto;*/
        display: flex;
        flex-direction: column;
        list-style: none;
        padding: 0;


    }

    .sub_menu > ul > li {
        /*width: calc(25% - 36px);*/
        width: 170px;

        padding-top: 26px;
        font-size: 1.4rem;
        line-height: 20px;
        letter-spacing: 0.35px;
        color: #333;
        text-align: left;
    }


    /*pc header end*/

    .subtop {
        width: 100%;
        height: 296px;
        border-top: 1px solid #ddd;
    }

    .subtop .subtopin {
        width: 1200px;
        margin: 0 auto;


    }

    .subtop .subtopin img {
        width: 418px;
        display: inline-block;
        margin-left: 10%;
    }

    .subtop .subtopin .subtit {
        display: inline-block;
        font-size: 4.5rem;
        letter-spacing: -2.25px;
        font-weight: 900;
        color: #000000;
        vertical-align: middle;
    }

    .sbreadcrumb {
        width: 100%;
        background: #0F70B7;
        height: 62px;
    }

    .sbreadcrumb .bdinner {
        width: 1200px;
        margin: 0 auto;
    }

    .sbreadcrumb .bdinner span {
        padding-right: 20px;
        vertical-align: bottom;
        display: inline-block;
        padding-top: 19px;
    }

    .sbreadcrumb .bdinner span img {
        vertical-align: sub;
    }

    .sbreadcrumb .bdinner span.locatit {
        font-size: 1.8rem;
        color: #fff;
        font-weight: 900;
        padding-top: 13px;
        display: inline-block;
    }

    .scontainer {
        width: 100%;
    }

    .scontainer .section {
        padding: 0px 30px;
    }

    .scontainer .section .sectionin {
        width: 1200px;
        margin: 0 auto;
        overflow: hidden;
        padding: 0;
    }

    .scontainer .section .sectionin .stit {
        color: #000;
        font-size: 4.5rem;
        letter-spacing: -1.15px;
        line-height: 70px;
        font-weight: 900;
        padding-top: 76px;
        text-align: center;
        display: inline-block;
    }

    .scontainer .section .sectionin .stxt {
        font-size: 1.8rem;
        color: #000;
        line-height: 18px;
        letter-spacing: -0.9px;
        text-align: center;
        padding-top: 12px;
        line-height: 27px;
        display: inline-block;
    }

    .manual_title_wrap {
        margin-left: 16%;
        display: grid;
    }


    /*_-------------------------------------------------------------------서브페이지 공통*/

    .spdown {
        display: block;
        text-align: right;
        padding-top: 40px;
    }

    .spdown  a {
        font-size: 1.4rem;
        font-weight: bold;
        color: #333;
    }

    .spdown  a:hover {
        color: #2D84E2;
    }

    .spdown  a img {
        width: 20px;
        margin: 0px 3px 3px 0px;
    }

    .scontainer .section .smenu {
        display: flex;
        flex-direction: column;
        margin-top: -272px;
        border-right: 1px solid #ddd;
        padding-top: 30px;
    }

    .scontainer .section .smenu .menutit {
        font-size: 1.8rem;
        letter-spacing: -1px;
        color: #000;
        font-weight: 600;
        margin-bottom: 16px;
        cursor: pointer;
    }

    .scontainer .section .smenu .menutit:hover {
        color: #2D84E2;
    }

    .scontainer .section .smenu .menutit.menuon {
        color: #2D84E2;
    }

    .scontainer .section .smenu .mlist .menutit img {
        vertical-align: inherit;
    }

    .scontainer .section .smenu .mlist ul li {
        display: block;
    }

    .scontainer .section .spcont {
        display: flex;
        margin-top: 80px;
        padding-left: 50px;
        /*border-left: 1px solid #ddd;*/
    }

    .scontainer .section .spcont .rightc {
        display: block;
        flex: none;
        width: 100%;
    }

    .scontainer .section .spcont .rightc h3 {
        font-size: 2.4rem;
        color: #000;
        letter-spacing: -1.2px;
        font-weight: 900;
        display: block;
    }

    .scontainer .section .spcont .rightc .spdown {
        display: block;
        text-align: right;

    }

    .scontainer .section .spcont .rightc .spdown a {
        color: #404040;
        font-size: 1.4rem;
    }

    .scontainer .section .spcont .rightc .spdown a img {
        width: 18px;
        margin-right: 4px;
        vertical-align: sub;
    }

    .scontainer .section .spcont .rightc .supcont {
        margin-top: 10px;
        font-size: 1.6rem;
        color: #000;
        line-height: 24px;
        letter-spacing: -1px;

    }

    .scontainer .section .spcont .rightc .supcont p {
        color: #000;
    }

    .scontainer .section .spcont .rightc .supcont img {
        width: 100%;
        border: 1px solid #e8e8e8;
        margin-bottom: 20px;
    }

    .m_spmenu {
        display: none;
    }

    .supcont div {
        margin-bottom: 80px;
    }

    .font_size {
        font-size: 1.8rem;
        /*font-weight: bold;*/
    }

    .font-weight-bold {
        font-size: 2.0rem;
    }

    .supcont > div > p {
        font-size: 2rem;
    }

    .five_content {
        width: 100%;
        height: 202px;
        background-color: #ececec;
        border-bottom: 1px solid #cccccc;
    }

    .five_content > ul {
        padding: 40px 0 0 0;
        font-weight: bold;
        color: #666;
        font-size: 1.4rem;
        width: 1200px;
        margin: 0 auto;
        list-style: none;
        display: flex;
        justify-content: space-between;
    }

    .five_content > ul > li > ul {
        width: 240px;
        list-style: none;
        padding: 16px 0 0 0;
        font-weight: normal;
        color: #9a9a9a;
    }

    .five_content > ul > li > ul > li {
        padding-bottom: 12px;
        cursor: pointer;
    }


    .footer {
        width: 100%;
        height: 100px;
        background-color: #ececec;
    }

    .footer_wrap {
        width: 1200px;
        margin: 0 auto;
        display: flex;
        justify-content: start;
        font-size: 1.3rem;
        color: #9a9a9a;
        padding: 23px 20px 0px 0px;
    }

    .footer_wrap > div {
        margin-top: -13px;
    }

    .footer_flex {
        line-height: 18px;
        width: 100%;
        padding-left: 180px;
    }

    .footer_flex span {
        display: block;
        margin-top: 4px;
        font-size: 1.3rem;
        color: #9a9a9a;
    }

    .familyarea {
        margin-left: 72px;
    }

    .familyarea select {
        margin-left: 20px;
    }

    select {
        background: transparent;
        border: 1px solid #cccccc !important;
        width: 170px !important;
        height: 30px;
        color: #9a9a9a;
    }


    /*---------------------------------------------------------------------------------------------------tablet*/
    @media screen and (max-width: 1199px) and (min-width: 750px) {

        /*모바일 헤더 메뉴 start */
        #header {
            width: 100%;
            margin: 0 auto;
            height: 50px;
        }

        .inner {
            display: flex;
            justify-content: space-between;
            line-height: 50px;
            padding: 0 16px;
        }

        .logo {
            width: 150px;
            height: 25px;
        }

        .logo > a > img {
            width: 100%;
        }

        .m_header div:nth-child(2) {
            width: 30px;
            height: 30px;
        }

        .m_header div:nth-child(2) img {
            width: 100%;
        }

        .m_header_submenu_wrap {
            position: fixed;
            top: 0;
            overflow-y: auto;
            overflow-x: hidden;
            right: 0;
            z-index: 9999;
            width: 100%;
            height: 100%;
            display: none;
        }

        .side_submenu_li_wrap li {
            list-style: none;
            padding-left: 20px;
            height: 40px;
            line-height: 40px;
            font-size: 1.6rem;
        }

        .side_submenu_li_wrap li a {
            color: #363636;
        }

        .side_submenu ul {
            width: 50%;
            height: 100%;
            background-color: white;
            position: fixed;
            right: 0;
            top: 0;
            padding: 0;
        }

        .sub_menu_background {
            position: fixed;
            width: 100%;
            height: 100%;
            background-color: #000000;
            opacity: 0.6;
        }

        .li_background {
            background-color: #EDF2F6;
        }

        .li_background li {
            padding-bottom: 5px;
        }

        .header_container {
            display: none;
        }

        .m_close_button {
            position: fixed;
            left: 45% !important;
            top: 4px !important;
        }

        .m_close_button > img {
            width: 24px !important;
            height: 24px !important;
        }

        .header_container {
            display: none;
            width: 100%;

        }

        .header_menu_wrap {
            display: none;
        }

        .m_close_button {
            position: fixed;
            left: 340px;
            top: -7px;
        }

        /*모바일 헤더 메뉴 end */
        .scontainer .section .sectionin {
            width: 100%;
        }

        .scontainer .section .smenu .mlist .menutit img {
            vertical-align: inherit;
            width: 16px;
        }

        .scontainer .section .smenu .mlist ul {
            padding-left: 20px;
        }

        .scontainer .section .smenu .mlist ul li {
            list-style: none;
            font-size: 1.3rem;
            letter-spacing: -0.8px;
            color: #000;
            line-height: 24px;
            padding-bottom: 4px;
        }

        .scontainer .section .smenu .mlist .menutit {
            font-weight: 900;
            font-size: 1.4rem;
            letter-spacing: -1px;
            color: #000;
            padding-bottom: 16px;
        }


        .scontainer .section .spcont {
            display: flex;
            margin-top: 60px;
            padding-left: 26px;
        }

        .m_spmenu {
            display: none;
        }

        .bdinner {
            padding-left: 20px;
        }

        .spdown a {
            text-decoration: underline;
        }

        .scontainer .section .smenu .menutit {
            font-size: 1.6rem;
            letter-spacing: -1px;
            color: #000;
            font-weight: 600;
            margin-bottom: 16px;
        }

        .scontainer .section .spcont .rightc .supcont {
            margin-top: 10px;
            font-size: 1.6rem;
            color: #000;
            line-height: 24px;
            letter-spacing: -1px;
            color: #000;
        }

        .subtop .subtopin img {
            width: 418px;
            display: inline-block;
            margin-left: 6%;
        }

        .five_content {
            width: 100%;
            padding: 0 40px;
        }

        .five_content > ul {
            width: 100%;
            margin: 0 auto;
            font-size: 1.3rem;
            letter-spacing: -0.5px;
        }

        .five_content > ul > li > ul {
            width: 100%;
        }

        .footer {
            width: 100%;
            height: 130px;
            background-color: #ececec;
            padding-bottom: 20px;
        }

        .footer .footer_wrap {
            width: 100%;
            padding: 0 40px;
        }

        .footer_wrap > div {
            margin-top: 14px;
        }

        .footer_flex {
            line-height: 18px;
            width: 100%;
            padding-left: 50px;
            margin-top: 20px;
            display: flex;
            flex-direction: column;
        }

        .footer_flex span {
            display: block;
            margin-top: 4px;
            font-size: 1.3rem;
            color: #9a9a9a;
        }

        .familyarea {
            margin-left: 0px;
            margin-top: 10px;
        }

        .familyarea select {
            margin-left: 0px;
        }

    }

    /*---------------------------------------------------------------------------------------------------mobile*/
    @media (max-width: 749px) {
        /*grid*/
        #header {
            width: 100%;
            margin: 0 auto;
            height: 50px;
        }

        .inner {
            display: flex;
            justify-content: space-between;
            line-height: 50px;
            padding: 0 16px;
        }

        .logo {
            width: 105px;
            height: 25px;
        }

        .logo > a > img {
            width: 100%;
        }

        /*모바일 헤더 메뉴 start */
        .m_header_submenu_wrap {
            position: fixed;
            top: 0;
            overflow-y: auto;
            overflow-x: hidden;
            right: 0;
            z-index: 9999;
            width: 100%;
            height: 100%;
            display: none;
        }

        .side_submenu_li_wrap li {
            list-style: none;
            padding-left: 20px;
            height: 40px;
            line-height: 40px;
        }

        .side_submenu_li_wrap li a {
            color: #363636;
        }

        .side_submenu ul {
            width: 70%;
            height: 100%;
            background-color: white;
            position: fixed;
            right: 0;
            top: 0;
            padding: 0;
            overflow-y: auto;
        }

        .sub_menu_background {
            position: fixed;
            width: 100%;
            height: 100%;
            background-color: #000000;
            opacity: 0.6;
        }

        .li_background {
            background-color: #EDF2F6;
        }

        .li_background li {
            padding-bottom: 5px;
        }

        .header_container {
            display: none;
        }

        .m_close_button {
            position: fixed;
            left: 24%;
            top: -7px;
        }

        /*모바일 헤더 메뉴 end */
        .row {
            margin-left: 0px;
            margin-right: 0px;
        }

        .subtop {
            width: 100%;
            height: 97px;
            border-bottom: 1px solid #0F70B7;
        }

        .subtop .subtopin {
            width: 100%;
        }

        .subtop .subtopin img {
            width: 135px;
            display: inline-block;
            margin-left: 10%;
        }

        .subtop .subtopin .subtit {
            display: inline-block;
            font-size: 2rem;
            letter-spacing: -1px;
            font-weight: 900;
            color: #000000;
            vertical-align: middle;
        }

        .sbreadcrumb {
            display: none;
        }


        .scontainer .section {
            padding: 32px 20px;
        }

        .scontainer .section .sectionin {
            width: 100%;
            overflow: hidden;
        }

        .scontainer .section .sectionin .stit {
            text-align: center;
            font-size: 2rem;
            color: #000;
            font-weight: 900;
            letter-spacing: -1px;
            line-height: 40px;
            padding-top: 28px;
        }

        /*menu*/
        .m_spmenu {
            width: 100%;
            display: block;
        }

        .m_spmenu .dropbtn {
            background-color: #fff;
            color: #000000;
            border: 1px solid #e1e1e1;
            border-radius: 4px;
            font-size: 1.3rem;
            letter-spacing: -0.65px;
            height: 40px;
            line-height: 40px;
            width: 100%;
            text-align: left;
            padding-left: 16px;

        }

        .sdropdown {
            position: relative;
            display: block;
            text-align: left;

        }

        .sdropdown-content {
            display: none;
            position: absolute;
            background-color: #fff;
            border: 1px solid #e1e1e1;
            box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.1);
            z-index: 1;
            width: 100%;
        }

        .sdropdown-content a {
            color: black;
            padding: 12px 16px;
            text-decoration: none;
            display: block;
            font-size: 1.3rem;
            color: #000;


        }

        .sdropdown-content a:hover {
            background-color: #ddd;
        }

        .sdropdown:hover .sdropdown-content {
            display: block;
        }

        /*.dropdown:hover .dropbtn {background-color: #3e8e41;}*/
        /*menu*/
        .m_smenu {
            display: none !important;
        }

        .spdown {
            display: block;
            text-align: right;
            padding-top: 0px;
        }

        .spdown a {
            font-size: 1.3rem;
            text-decoration: underline;
        }

        .manual_title_wrap {
            margin-left: 0;
            display: grid;
        }

        .scontainer .section .spcont {
            display: block;
            margin-top: 54px;
            padding-left: 0px;
        }

        .scontainer .section .spcont .rightc h3 {
            font-size: 1.4em;
            color: #000;
            letter-spacing: -1.2px;
            font-weight: 900;
            display: block;
            margin-bottom: 15px;
        }


        .scontainer .section .spcont .rightc .supcont {
            margin-top: 10px;
            font-size: 1.3rem;
            color: #000;
            line-height: 24px;
            letter-spacing: -1px;
            color: #000;
        }

        .scontainer .section .spcont .rightc .supcont img {
            margin-bottom: 20px;
        }

        .scontainer .section .spcont {
            border-left: none;
        }

        .font-weight-bold {
            font-size: 1.4rem !important;
        }

        .font_size {
            font-size: 1.4rem !important;
        }

        .supcont > div > p {
            font-size: 1.3rem;
        }

        .five_content {
            display: none;
        }

        .supcont div {
            margin-bottom: 48px;
        }

        .footer {
            width: 100%;
            height: 197px;
            padding: 0 20px;
        }

        .footer_wrap {
            flex-direction: column;
            width: 100%;
            padding-top: 0;
        }

        .footer_wrap > div {
            text-align: center;
            margin: 0 auto;
        }

        .footer_wrap > div > img {
            width: 140px;
            height: 55px;
        }

        .footer_flex {
            padding-left: 0;
            flex-direction: column-reverse;
        }

        .foottxt {
            width: 100%;
            margin: 0 auto;
            padding-top: 25px;
        }

        .familyarea {
            margin-left: 0;
            padding-top: 10px;
        }

        select {
            background: transparent;
            border: 1px solid #cccccc !important;
            width: 100% !important;
        }


    }

</style>

<script>
    import CommonHeader from "@/components/CommonHeader";
    import CommonSiteMap from "@/components/CommonSiteMap";
    import CommonFooter from "@/components/CommonFooter";
    export default {
        name: "Support",
        components: {CommonFooter, CommonSiteMap, CommonHeader}
    }
</script>