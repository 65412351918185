<template>
    <div class="five_content">
        <ul>
            <li><a href="about">ABOUT</a>
                <ul>
                    <li>Profile</li>
                    <li>MPS Business</li>
                    <li>SI Business</li>
                </ul>
            </li>
            <li><a href="products">PRODUCT</a>
                <ul>
                    <li>TN-BOX(Web)</li>
                    <li>TN-BOX(Mobile)</li>
                </ul>
            </li>
            <li><a href="partners">PARTNERS</a>
                <ul>
                    <li>Partners Programs</li>
                    <li>Becoming a Partners</li>
                </ul>
            </li>
            <li><a href="support">SURPPORT</a>
                <ul>
                    <li>Manual Download</li>
                    <li>Remote Download</li>
                </ul>
            </li>
            <li><a href="contactUs"> CONTACT US</a>
                <ul>
                    <li>Message & Info</li>
                </ul>
            </li>
        </ul>
    </div>
</template>

<style scoped>
    .five_content {
        width: 100%;
        height: 202px;
        background-color: #ececec;
        border-bottom: 1px solid #cccccc;
    }

    .five_content > ul {
        padding: 40px 0 0 0;
        font-weight: bold;
        color: #666;
        font-size: 1.4rem;
        width: 1200px;
        margin: 0 auto;
        list-style: none;
        display: flex;
        justify-content: space-between;
    }

    .five_content > ul > li > a {
        color: #9a9a9a;
    }

    .five_content > ul > li > ul {
        width: 240px;
        list-style: none;
        padding: 16px 0 0 0;
        font-weight: normal;
        color: #9a9a9a;
    }

    .five_content > ul > li > ul > li {
        padding-bottom: 12px;
        cursor: pointer;
    }

    @media screen and (max-width: 1199px) and (min-width: 750px) {
        /*sitemap*/
        .five_content {
            width: 100%;
            padding: 0 40px;
        }

        .five_content > ul {
            width: 100%;
            margin: 0 auto;
            font-size: 1.3rem;
            letter-spacing: -0.5px;
        }

        .five_content > ul > li > a {
            color: #9a9a9a;
        }

        .five_content > ul > li > ul {
            width: 100%;
        }

    }

    @media (max-width: 749px) {
        .five_content {
            display: none;
        }
    }
</style>

<script>
    export default {
        name: 'CommonSiteMap'
    }
</script>
